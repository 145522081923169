import style from './Card.module.scss';
import './Card.css';

// React
import { useEffect, useState } from 'react';

// Ant Design
import { Slider } from 'antd';
import { message as antdMessage } from 'antd';

// Router
import { useNavigate } from 'react-router-dom';

// Telegram
import { BackButton } from '@vkruglikov/react-telegram-web-app';

// Images
import tonImg from '../../assets/img/wallet/ton.svg';
import tetherImg from '../../assets/img/wallet/tether.svg';
import bitcoinImg from '../../assets/img/wallet/bitcoin.svg';

// Redux
import { useSelector } from 'react-redux';
import { API_SERVER_WEBSOCKET } from '../../http';
import { useAppDispatch } from '../../redux/store';
import { setRates } from '../../redux/rates/slice';
import { selectRates } from '../../redux/rates/selectors';
import { selectWallets } from '../../redux/wallets/selectors';
import { requestGetWalletBtc, requestGetWalletTon, requestGetWalletUsdt } from '../../redux/wallets/asyncActions';

// Websocket
import useWebSocket, { ReadyState } from 'react-use-websocket';


const Card = () => {
    const navigate = useNavigate();
    const [activeCoin, setActiveCoin] = useState<number>(-1);
    const [valueCoin, setValueCoin] = useState<number>(0);

    const handleSliderChange = (value: number) => {
        setValueCoin(value);
    }

    // Redux
    const dispatch = useAppDispatch();

    // Alert
    const [messageApi, contextHolder] = antdMessage.useMessage();

    // Get Wallets
    const { status, message, ton_wallet, btc_wallet, usdt_wallet } = useSelector(selectWallets);

    useEffect(() => {
        if (!ton_wallet && message.length <= 0) {
            dispatch(requestGetWalletTon());
        }
    }, [dispatch, ton_wallet, message])

    useEffect(() => {
        if (!btc_wallet && message.length <= 0) {
            dispatch(requestGetWalletBtc())
        }
    }, [dispatch, btc_wallet, message])

    useEffect(() => {
        if (!usdt_wallet && message.length <= 0) {
            dispatch(requestGetWalletUsdt())
        }
    }, [dispatch, usdt_wallet, message])

    useEffect(() => {
        if (message.length > 0) {
            messageApi.error(message, 2);
        }
    }, [message])

    // Socket Get Rates
    const { tonRub, tonUsd, tetherRub, tetherUsd, bitcoinRub, bitcoinUsd } = useSelector(selectRates);

    const token = localStorage.getItem('access_token');
    const WS_RATE_URL = `${API_SERVER_WEBSOCKET}/rate/ws`;
    const { lastJsonMessage: lastJsonMessageRate, readyState: readyStateRate } = useWebSocket(
        WS_RATE_URL,
        {
            share: false,
            reconnectInterval: 2000,
            shouldReconnect: () => true,
            queryParams: {
                'token': token ? token.toString() : '',
            },
        },
    )

    useEffect(() => {
        if (readyStateRate === ReadyState.OPEN) {
            if (lastJsonMessageRate && typeof lastJsonMessageRate === 'string') {
                const rates = JSON.parse(lastJsonMessageRate);
                dispatch(setRates({
                    tonUsd: rates['the-open-network/usd']?.rate,
                    tonRub: rates['the-open-network/rub']?.rate,
                    bitcoinUsd: rates['bitcoin/usd']?.rate,
                    bitcoinRub: rates['bitcoin/rub']?.rate,
                    tetherUsd: rates['tether/usd']?.rate,
                    tetherRub: rates['tether/rub']?.rate,
                }));
            }
        }
    }, [readyStateRate, lastJsonMessageRate, dispatch])

    const handleNavigate = () => {
        navigate('/home/verification/step1');
    }

    return (
        <div className={style.card}>
            <BackButton onClick={() => navigate(-1)} />
            {contextHolder}
            <h2>Получить средства</h2>
            <div className={style.card__tokens}>
                <h3 className={style.card__tokens__sub_title}>Выберите токен</h3>
                <div className={style.card__tokens__flex}>
                    <div
                        onClick={() => setActiveCoin(0)}
                        className={`${style.card__tokens__token} ${activeCoin === 0 && style.active}`}>
                        <div className={style.card__tokens__token__header}>
                            <img src={tetherImg} alt="tether" />
                            <p>USDT</p>
                        </div>
                        <div className={style.card__tokens__token__footer}>
                            <p>{usdt_wallet && tetherRub && (tetherRub * usdt_wallet.balance_crypto).toFixed(2)} ₽</p>
                            <p>{usdt_wallet?.balance_crypto.toFixed(4)} USDT</p>
                        </div>
                    </div>

                    <div
                        onClick={() => setActiveCoin(1)}
                        className={`${style.card__tokens__token} ${activeCoin === 1 && style.active}`}>
                        <div className={style.card__tokens__token__header}>
                            <img src={tonImg} alt="ton" />
                            <p>TON</p>
                        </div>
                        <div className={style.card__tokens__token__footer}>
                            <p>{ton_wallet && tonRub && (tonRub * ton_wallet.balance_crypto).toFixed(2)} ₽</p>
                            <p>{ton_wallet?.balance_crypto.toFixed(4)} TON</p>
                        </div>
                    </div>

                    <div
                        onClick={() => setActiveCoin(2)}
                        className={`${style.card__tokens__token} ${activeCoin === 2 && style.active}`}>
                        <div className={style.card__tokens__token__header}>
                            <img src={bitcoinImg} alt="btc" />
                            <p>BTC</p>
                        </div>
                        <div className={style.card__tokens__token__footer}>
                            <p>{btc_wallet && bitcoinRub && (bitcoinRub * btc_wallet?.balance_crypto).toFixed(2)} ₽</p>
                            <p>{btc_wallet?.balance_crypto.toFixed(4)} BTC</p>
                        </div>
                    </div>
                </div>
            </div>

            {activeCoin !== -1 && (
                <>
                    <div className={style.card__loan_amount}>
                        <h3>Введите сумму займа</h3>
                        <span className={style.card__loan_amount__input}>
                            <input min={0} value={valueCoin} onChange={(e: any) => setValueCoin(e.target.value)} type="number" placeholder='' />
                        </span>
                    </div>

                    <div className={style.card__unrequired_collateral}>
                        <Slider onChange={handleSliderChange} value={valueCoin} min={0} max={100000} defaultValue={30} />
                        <div className={style.card__unrequired_collateral__value}>
                            <p>Необходимый залог: <span>
                                {activeCoin === 0 && tetherRub ? (valueCoin / tetherRub).toFixed(4) 
                                : activeCoin === 1 && tonRub ? (valueCoin / tonRub).toFixed(4)
                                : bitcoinRub && (valueCoin / bitcoinRub).toFixed(4)}
                                {activeCoin === 0 ? ' USDT' : activeCoin === 1 ? ' TON' : ' BTC'}</span></p>
                        </div>
                    </div>

                    {valueCoin !== 0 && (
                        <div className={style.card__accept_btn}>
                            {/* <button onClick={handleNavigate} disabled={activeCoin === 0 && usdt_wallet?.balance_crypto === 0 ? true
                                                : activeCoin === 1 && ton_wallet?.balance_crypto === 0 ? true
                                                : activeCoin === 2 && btc_wallet?.balance_crypto === 0 ? true : false}>Подтвердить</button> */}
                            <button onClick={handleNavigate}>Подтвердить</button>
                        </div>
                    )}
                </>
            )}
        </div>
    )
}

export default Card;